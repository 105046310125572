import {
  CustomerLatestClosingsOption,
  CustomerYearsAsRealtorOption,
  NotificationConfirmInputs,
} from "@Types/common"
import { NotificationMethod, NotificationType, SocialMediaTypes, Source } from "db"

export const IDLE_TIME = 36 * 60 * 60 * 1000 // seconds

export const FACEBOOK_PROFILE_URL = "https://www.facebook.com/"
export const INSTAGRAM_PROFILE_URL = "https://www.instagram.com/"
export const LINKEDIN_PROFILE_URL = "https://www.linkedin.com/in/"
export const TWITTER_PROFILE_URL = "https://www.twitter.com/"
export const ZILLOW_PROFILE_URL = "https://www.zillow.com/profile/"

export const COMMON_REGEX = {
  phoneNumber: /^(?:\+?\d[-.\s]?)?\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/,
  email: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  url: /^((https?|ftp|smtp):\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]+(\/[a-zA-Z0-9#]+\/?)*$/,
  numericString: /^\d*$/,
  zillowReviewLink: /^(https:\/\/(www\.)?zillow\.com\/reviews\/write\/\?s=[\w-]+)?$/i,
  googleReviewLink: /^(https:\/\/search\.google\.com\/local\/writereview\?placeid=[\w-]+)?$/i,
  facebookLink: /^(https:\/\/(www\.)?facebook\.com\/(profile.php\?id=\d+|[a-z\d\.]+\/?))?$/i,
  instagramLink: /^(https:\/\/(www\.)?instagram\.com\/[\w\.]+\/?)?$/i,
  twitterLink: /^(https:\/\/(www\.)?twitter\.com\/[\w]+\/?)?$/i,
  linkedinLink: /^(https:\/\/(www\.)?linkedin\.com\/in\/[a-z\d\-]+\/?)?$/i,
  zillowLink: /^(https:\/\/(www\.)?zillow\.com\/profile\/[a-z\d\s\-\_]+\/?)?$/i,
}

export const genderToCourtesyTitleMap = {
  male: "Mr",
  female: "Ms",
  other: "",
}

export const genderLabel = {
  male: "Male",
  female: "Female",
  other: "Other",
}

export const statesAndTerritoriesByAbbr = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

export const statesAndTerritoriesByName = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
}

export const STATIC_AVATARS = {
  female: "/images/avatar1.png",
  femaleSecondary: "/images/avatar5.png",
  male: "/images/avatar2.png",
  unisex: "/images/avatar3.png",
  zeroState: "/images/avatar4.png",
}

export const importSources = [
  {
    source: Source.FollowUpBoss,
    value: "Follow Up Boss",
  },
  {
    source: Source.KvCore,
    value: "KvCore",
  },
]

export const CONSENT_INPUTS_TO_TYPE_AND_METHOD = {
  [NotificationConfirmInputs.NotifyAppointmentCancelledSmsField]: {
    type: NotificationType.APPOINTMENT_CANCELLED,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyAppointmentReminderSmsField]: {
    type: NotificationType.APPOINTMENT_BROKER_REMINDER,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyAppointmentReminderAiRapportSmsField]: {
    type: NotificationType.APPOINTMENT_AI_REPORT,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyAppointmentReminderEmailField]: {
    type: NotificationType.APPOINTMENT_BROKER_REMINDER,
    method: NotificationMethod.EMAIL,
  },
  [NotificationConfirmInputs.NotifyBrokerNbaReminderEmailField]: {
    type: NotificationType.NBA_BROKER_REMINDER,
    method: NotificationMethod.EMAIL,
  },
  [NotificationConfirmInputs.NotifyBrokerNbaReminderSmsField]: {
    type: NotificationType.NBA_BROKER_REMINDER,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyBrokerIntakeFormEmailField]: {
    type: NotificationType.INTAKE_FORM_REMINDER,
    method: NotificationMethod.EMAIL,
  },
  [NotificationConfirmInputs.NotifyBrokerIntakeFormSmsField]: {
    type: NotificationType.INTAKE_FORM_REMINDER,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyBrokerClosingDateSmsField]: {
    type: NotificationType.CLOSING_DATE_REMINDER,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyBrokerDailyIntakeFormEmailField]: {
    type: NotificationType.INTAKE_FLOW_NOTIFICATION,
    method: NotificationMethod.EMAIL,
  },
  [NotificationConfirmInputs.NotifyBrokerDailyIntakeFormSmsField]: {
    type: NotificationType.INTAKE_FLOW_NOTIFICATION,
    method: NotificationMethod.SMS,
  },
  [NotificationConfirmInputs.NotifyBrokerNewNbaCrmWritebackField]: {
    type: NotificationType.NEW_NBA_CRM_NOTE,
    method: NotificationMethod.CRM_NOTE,
  },
}

export const CONSENT_TYPE_AND_METHOD_COMBINATIONS = Object.values(CONSENT_INPUTS_TO_TYPE_AND_METHOD)

export const SocialMediaIcon = {
  [SocialMediaTypes.FACEBOOK]: "/images/icons/facebook-colorful-circle.png",
  [SocialMediaTypes.INSTAGRAM]: "/images/icons/instagram-colorful-circle.png",
  [SocialMediaTypes.TWITTER]: "/images/icons/twitter-colorful-circle.png",
  [SocialMediaTypes.LINKEDIN]: "/images/icons/linkedin-colorful-circle.png",
  [SocialMediaTypes.ZILLOW]: "/images/icons/zillow-colorful-circle.png",
}

export const DEFAULT_MIN_IMAGE_DIMENSION = 200

export const DATE_FORMAT = "M/D/YY"

export const DEFAULT_TIMEZONE = "America/New_York"

export const SQUARE_FEET_IN_ACRE = 43560

export const SMS_CHARACTER_LIMIT = 640

export const VIDEO_STREAMING_ENABLED = false

export const YEARS_AS_REALTOR_OPTIONS: CustomerYearsAsRealtorOption[] = [
  {
    label: "1 year",
    value: "1 year",
  },
  {
    label: "2 years",
    value: "2 years",
  },
  {
    label: "3 years",
    value: "3 years",
  },
  {
    label: "4 years",
    value: "4 years",
  },
  {
    label: "5 years",
    value: "5 years",
  },
  {
    label: "6-10 years",
    value: "6-10 years",
  },
  {
    label: "11-20 years",
    value: "11-20 years",
  },
  {
    label: "21-30 years",
    value: "21-30 years",
  },
  {
    label: "30+ years",
    value: "30+ years",
  },
]
export const LATEST_CLOSINGS_OPTIONS: CustomerLatestClosingsOption[] = [
  {
    label: "1 closing",
    value: 1,
  },
  {
    label: "2 closings",
    value: 2,
  },
  {
    label: "3 closings",
    value: 3,
  },
  {
    label: "4 closings",
    value: 4,
  },
  {
    label: "5 closings",
    value: 5,
  },
  {
    label: "6-10 closings",
    value: 6,
  },
  {
    label: "11-20 closings",
    value: 11,
  },
  {
    label: "21-30 closings",
    value: 21,
  },
  {
    label: "30+ closings",
    value: 31,
  },
]

export const SCALED_FILE_TAG = "_scaled_"

export const CRM_IMPORT_FAILURES_THRESHOLD = 3

export const DEFAULT_CRM_LOOKBACK_DAYS = 30

export const NIGHT = {
  start: 20,
  end: 8,
}
